/* stylelint-disable selector-max-id, selector-id-pattern, declaration-no-important */
html,
body {
	width: 100%;
	font-size: 100%;
}

body {
	height: 100%;
	font-family: Montserrat, HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	color: #444;
	line-height: 1.331em;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizelegibility;
}

/* Vilken bredd på grid kör du? Ändra 1200 till grid bredd :) */
.row {
	max-width: 960px;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

article ul,
article ol {
	margin-bottom: 1.5em;
	margin-left: 30px;
	line-height: 1.7em;
}

article {
	min-height: 300px;
}

/* ==========================================================================
   Typography
========================================================================== */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	margin: 0 0 .5em;
	line-height: 1.4em;
	text-transform: uppercase;
	color: #111;
	font-weight: 400;
}

h1,
.h1 {
	font-size: 20px;
	margin-bottom: $standard;
}

h2,
.h2 { font-size: 20px; }

h3,
.h3 { font-size: 18px; }

h4,
.h4 { font-size: 16px; }

h5,
.h5 { font-size: 14px; }

h6,
.h6 {
	font-size: 14px;
	margin-bottom: $half;
}

p {
	margin: 0 0 1.5em;
	line-height: 1.7em;
}

blockquote {
	border-left: 3px solid $blue;
	padding-left: 20px;
	font-size: 1.7em;
	color: $blue;
	font-style: italic;
}

cite {
	color: #444;
	font-size: 12px;
	font-style: normal;

	&:before {
		content: "– ";
	}
}

/* ==========================================================================
   Alignments
========================================================================== */

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin: 0 auto;
}

/* ==========================================================================
   Links
========================================================================== */

a {
	color: $blue;
	text-decoration: none;
	transition: color .2s;
}

a:active,
a:visited {
	color: $blue;
	text-decoration: none;
}

a:hover,
a:focus {
	color: #363636;
	text-decoration: none;
	outline: none;
}

article a {
	text-transform: uppercase;
}

/* ==========================================================================
   Header
========================================================================== */

#logo {
	margin: $tripple auto;
}

#language {
	float: right;
	text-align: right;
	font-size: 12px;
	margin-top: $standard;
	margin-right: $standard;
}

/* ==========================================================================
   Navigation
========================================================================== */

nav ul {
	list-style: none;
	margin: 0;
	padding: 0;

	.children {
		margin-left: 15px;
		margin-bottom: 20px;
		display: none;
	}

	.current_page_item .children,
	.current_page_parent .children {
		display: block;
	}
}

nav ul li a,
nav ul li a:active,
nav ul li a:visited {
	color: $blue;
	text-transform: uppercase;
}

// mainnav
#nav ul {
	margin: 0 auto $tripple;
	text-align: center;
	display: block;
	width: 100%;
}

#nav ul li {
	display: inline-block;
	margin: 0 23px;
}

#nav ul li a {
	padding: 10px 15px;
	margin: 0;
	font-size: 14px;
	border: 1px solid $white;

	&:hover {
		border: 1px solid $blue;
		transition: height .3s, opacity .3s, transform .3s;
	}
}

#nav ul li.current-menu-item a,
#nav ul li.current_page_item a,
#nav ul li.current_page_parent a,
#nav ul li.current-page-ancestor a {
	border: 1px solid $blue;
}

// subnav
#subnav ul {
	margin-top: 8px;
}

#subnav ul li a {
	padding: 15px;
	display: block;
	border: 1px solid $blue;
	margin-bottom: $half;
	font-size: 12px;

	&:hover {
		color: $white;
		background: $blue;
	}
}

#subnav ul li.current_page_item > a {
	color: $white;
	background: $blue;
}

// mobilde nav
.container-fluid {
	background: $blue;
}

#nav-toggle {
	position: relative;
	float: right;
	margin: $standard;
	display: none;
	cursor: pointer;
	padding: 10px 25px 16px 0;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
	cursor: pointer;
	border-radius: 1px;
	height: 3px;
	width: 25px;
	background: $blue;
	position: absolute;
	display: block;
	content: "";
	transition: all 500ms ease-in-out;
}

#nav-toggle span:before { top: -8px; }

#nav-toggle span:after { bottom: -8px; }

#nav-toggle.active span { background-color: transparent; }

#nav-toggle.active span:before,
#nav-toggle.active span:after { top: 0; }

#nav-toggle.active span:before {
	transform: rotate(45deg);
}

#nav-toggle.active span:after {
	transform: translateY(-10px) rotate(-45deg);
	top: 10px;
}

.navicon {
	position: relative;
	height: 26px;
}

.indicator {
	position: absolute;
	right: 0 !important;
}

.pullslide {
	display: none;
	color: $white;
	padding-top: $standard;
	padding-bottom: $standard;
}

#mobnav {
	a {
		padding: 10px 15px;
		font-size: 14px;
		border: 1px solid $white;
		color: $white;
		display: block;
		width: 100%;
		margin: $half auto;
		text-align: center;
		text-transform: uppercase;
	}
}

.page-id-215 .menu-item-509 a {
	border: 0 !important;

	&:hover {
		border: 1px solid $blue !important;
	}
}

/* ==========================================================================
   Content
========================================================================== */

.arrowbox {
	margin: -20px auto $tripple;
	width: 40px;
	height: 40px;
	text-align: center;
	background: $blue;
	position: relative;
	z-index: 300;
	@include opacity(.8);

	&:hover {
		@include opacity(1);
		@include transition;
	}

	a {
		color: $white;
		display: block;
		clear: both;
		line-height: 40px;
		font-size: 2em;
	}
}

.gform_wrapper,
.gform_wrapper .top_label textarea.textarea {
	width: 100% !important;
	max-width: 100% !important;
}

.gform_wrapper input[type="text"],
.gform_wrapper input[type="url"],
.gform_wrapper input[type="email"],
.gform_wrapper input[type="tel"],
.gform_wrapper input[type="number"],
.gform_wrapper input[type="password"],
textarea {
	border: 1px solid #ccc;
	padding: $half !important;
}

.gform_wrapper input[type="submit"] {
	background: $blue;
	color: $white;
	border: 1px solid $blue;
	padding: $half $double;
	text-transform: uppercase;
	font-weight: normal;
	font-size: 12px;
	text-shadow: none;
	@include rounded(2px);

	&:hover {
		@include opacity(.9);
	}
}

#gform_wrapper_2 {
	.top_label input.medium {
		width: 100% !important;
	}

	#field_2_1 {
		clear: none !important;
		float: left !important;
		width: 49% !important;

		.ginput_container {
			width: 100% !important;
		}
	}

	#field_2_2 {
		clear: none !important;
		float: right !important;
		width: 49% !important;

		.ginput_container {
			width: 100% !important;
		}
	}
}

/* Front page */
#hero {
	background: 50% 50% no-repeat fixed;
	background-size: cover;
	height: 360px;
	max-height: 360px;
	margin: 0 auto;
	width: 100%;
	position: relative;

	h1 {
		color: $white;
		font-size: 2.4em;
		line-height: 1.8em;
		text-transform: none;
		font-weight: 700;
		text-align: center;
		margin: 0;
		margin-top: 16%;
	}
}

#iconboxes {
	margin-bottom: $tripple;

	.iconbox {
		width: 100%;
		border: 1px solid $blue;
		text-align: center;
		padding: $double $half;

		&:hover {
			background: $blue;
			@include transition;
		}
	}

	a {
		color: $blue;
		text-transform: uppercase;

		&:hover {
			color: $white;
			@include transition;
		}
	}

	.fa {
		font-size: 3em;
		margin-bottom: $standard;
	}

	span {
		display: block;
	}
}

#intro {
	text-align: center;
	margin-bottom: $tripple;

	a {
		text-transform: uppercase;
	}

	.fa {
		font-size: 15px;
		margin-left: 3px;
	}
}

#textslider {
	margin-bottom: $tripple;

	.owl-stage-outer {
		border: 1px solid $blue;
		text-align: center;
		padding-top: $double;
		padding-bottom: $double;
	}

	a {
		text-transform: uppercase;
		margin-left: 15px;
		margin-right: 15px;
		text-align: center;
		display: block;
		line-height: 1.6em;
	}

	.fa {
		font-size: 15px;
		margin-left: 3px;
	}
}

.owl-controls {
	display: block;
	clear: both;
	text-align: center;
	margin-top: $standard;
}

.owl-dot {
	margin-left: 5px;
	margin-right: 5px;
	height: 10px;
	width: 10px;
	background: $black;
	display: inline-block;
	border: 1px solid $blue;
	@include rounded(5px);

	&.active {
		background: none;
	}
}

#main {
	margin-bottom: $tripple;
}

.titlebar {
	background-position: 50% 50%;
	background-size: cover;
	text-align: center;
	margin-bottom: $tripple;

	h1 {
		padding: 50px 0;
		margin: 0;
		color: $white;
		font-size: 2em;
	}
}

.ingress {
	color: $blue;
	font-size: 2em;
}

/* Organisation */
.person {
	padding-bottom: $double;
	border-bottom: 1px solid #ccc;
	margin-bottom: $double;
	display: block;

	p {
		margin: $half 0 0;

		a {
			text-transform: none;
		}
	}
}

/* Filer och dokument */
#files,
#files-archive {
	border-top: 1px solid #ccc;
	margin-top: $double;
	padding-top: $double;
	display: block;
}

.file {
	margin-bottom: $half;

	.fa {
		margin-right: 6px;
		font-size: 14px;
		line-height: 12px;
	}
}

/* Accordion */
.accordion {
	overflow: hidden;
	margin-top: -5px;
}

.accordion-section-title {
	width: 100%;
	padding: 15px;
	display: inline-block;
	background: #f5f5f5;
	transition: all linear .15s;
	margin-top: $half;
	line-height: 14px;
}

.accordion-section-content {
	padding: 15px;
	display: none;
}

.triggericon {
	transition: all 300ms ease-in-out;
	float: right;
	font-size: 18px;
}

.active .triggericon {
	display: inline-block;
	transform: rotate(.125turn);
}

/* ==========================================================================
   Map
========================================================================== */

.map_canvas {
	height: 395px;
	margin-bottom: $tripple;
	display: block;
	clear: both;
}

.map_canvas img {
	max-width: inherit;
}

.map_canvas .infotext {
	display: none;
}

/* ==========================================================================
   Sidebar
========================================================================== */

#sidebar {
	margin-right: 40px;
}

/* ==========================================================================
   Footer
========================================================================== */

#infoboxes {
	margin-top: $tripple;
}

.arrowbox.up {
	margin: $tripple auto -20px;
}

.infobox {
	width: 100%;
	border: 1px solid $blue;
	text-align: center;
	padding: $double $half;

	.fa {
		color: $blue;
		font-size: 1.5em;
		margin-bottom: 10px;
	}

	.infobox-item:first-child {
		margin-bottom: $standard;
	}
}

#riskinfo {
	margin-top: $tripple + 10px;
	border-top: 1px solid #ccc;
	padding-top: $tripple;

	p {
		margin: 0;
	}
}

#footer {
	background: #00255c;
	color: $white;
	padding-top: $tripple;
	padding-bottom: $tripple;
	text-transform: uppercase;

	p {
		margin: 0;
	}

	a {
		color: $white;

		&:hover {
			color: $blue;
		}
	}
}

#disclaimer {
	text-align: right;
}

/* ==========================================================================
   Waypoints
========================================================================== */

.wp0,
.wp1,
.wp2,
.wp3,
.wp4,
.wp5,
.wp6 { visibility: hidden; }

.bounceInLeft,
.bounceInRight,
.fadeIn,
.fadeInUp,
.fadeInUpDelay,
.fadeInDown,
.fadeInUpD,
.fadeInLeft,
.fadeInRight { visibility: visible; }

.delay-500ms {
	animation-delay: .5s;
}

.delay-1000ms {
	animation-delay: 1s;
}

.delay-1500ms {
	animation-delay: 1.5s;
}

/* ==========================================================================
   Media Queries
========================================================================== */

/* Smaller than standard 960 (devices and browsers) */
@media only screen and (max-width: 959px) {
	#nav,
	#language {
		display: none;
	}

	#nav-toggle {
		display: block;
	}

	.row {
		max-width: 90%;
	}

	#footer {
		p,
		#disclaimer {
			text-align: center !important;
		}

		#disclaimer {
			margin-top: $half;
		}
	}
}

/* Custom */
@media only screen and (min-width: 641px) and (max-width: 1024px) {
	#iconboxes {
		margin-bottom: $double;

		.item-4 {
			display: none;
		}
	}

	#hero {
		background: 50% 50% no-repeat scroll;
		background-size: 160%;
	}

	#footer {
		p,
		#disclaimer {
			text-align: center !important;
		}

		#disclaimer {
			margin-top: $half;
		}
	}
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
	#sidebar {
		margin-right: 0;
	}

	#iconboxes {
		margin-bottom: $double;

		.iconbox {
			padding: $standard $half;
			margin-bottom: $standard;
		}

		.fa {
			font-size: 3em;
			margin-bottom: $half;
		}

		span {
			font-size: 11px;
		}
	}

	.infobox {
		padding: $standard $half;
		margin: $half 0;
	}

	.titlebar {
		background-position: 40% 50%;
	}

	#hero {
		background: 50% 50% no-repeat scroll;
		background-size: 250%;
	}

	.arrowbox.up {
		margin: $double auto -20px;
	}
}

/* Mobile Landscape Size to Tablet Portrait (devices and browsers) */
@media only screen and (min-width: 480px) and (max-width: 767px) {
	#hero {
		background: 50% 50% no-repeat scroll;
		background-size: 200%;
	}
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (max-width: 479px) {
	#hero {
		height: 300px;
		max-height: 300px;
		background: 50% 50% no-repeat scroll;
		background-size: 250%;

		h1 {
			font-size: 2em;
			line-height: 1.8em;
		}

		.columns {
			padding: 0;
		}
	}

	#subnav ul {
		margin-top: 0;
	}

	#sidebar {
		margin-top: $tripple;
	}

	.map_canvas {
		height: 206px;
	}

	.titlebar {
		background-position: 30% 50%;
	}
}
