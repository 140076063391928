@import "foundation";
@import "mixin";
@import "animate.css";

@import "Generic";

@import "owl.carousel/dist/assets/owl.carousel.css";
@import "fontawesome-4.7"; // stylelint-disable-line scss/at-import-partial-extension

// Fix iconboxes fadeIn after buildjob was added
.fadeInUp {
	animation-name: fadeInUp;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}
