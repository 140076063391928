$white: #fff;
$black: #141414;
$red: #df0d57;
$lightgray: #f5f5f5;
$darkgray: #e1e1e1;
$gray: #f8f8f8;
$blue: #223d82;
$darkblue: #223d82;
$lightblue: #223d82;
$half: 10px;
$standard: 20px;
$double: 40px;
$tripple: 60px;

@mixin rounded($radius: 2px) {
	border-radius: $radius;
}

@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	border-radius: $topleft $topright $bottomright $bottomleft;
	@include background-clip(padding-box);
}

@mixin opacity($opacity: .5) {
	opacity: $opacity;

	$opperc: $opacity * 100;

	filter: #{"progid:DXImageTransform.Microsoft.Alpha(opacity=${opperc})"};
	filter: #{"alpha(opacity=${opperc})"};
}

@mixin transform( $arguments ) {
	transform: $arguments;
}

@mixin rotation($deg:5deg) {
	@include transform(rotate($deg));
}

@mixin scale($ratio:1.5) {
	@include transform(scale($ratio));
}

@mixin transition($type: all, $duration:.2s, $ease:ease-out) {
	transition: $type $duration $ease;
}

@mixin translate($x:0, $y:0) {
	@include transform(translate($x, $y));
}

@mixin background-clip($argument: padding-box) {
	background-clip: $argument;
}
