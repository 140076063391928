/* stylelint-disable declaration-no-important, no-duplicate-selectors, selector-max-id, selector-id-pattern, font-family-no-missing-generic-family-keyword, number-max-precision */
*,
*:before,
*:after {
	box-sizing: border-box;
}

a:hover {
	cursor: pointer;
}

img,
object,
embed {
	max-width: 100%;
	height: auto;
}

object,
embed {
	height: 100%;
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}

.clearfix {
	*zoom: 1;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-justify {
	text-align: justify !important;
}

.hide {
	display: none;
}

.start {
	float: left !important;
}

.end {
	float: right !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	display: inline-block;
	vertical-align: middle;
}

textarea {
	height: auto;
	min-height: 50px;
}

select {
	width: 100%;
}

.row {
	width: 100%;
	margin: 0 auto;
	*zoom: 1;
}

.row:before,
.row:after {
	content: " ";
	display: table;
}

.row:after {
	clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
	position: relative;
	padding-left: 0;
	padding-right: 0;
	float: left;
}

.row.collapse .row {
	margin-left: 0;
	margin-right: 0;
}

.row .row {
	width: auto;
	margin: 0 -.9375em;
	max-width: none;
	*zoom: 1;
}

.row .row:before,
.row .row:after {
	content: " ";
	display: table;
}

.row .row:after {
	clear: both;
}

.row .row.collapse {
	width: auto;
	margin: 0;
	max-width: none;
	*zoom: 1;
}

.row .row.collapse:before,
.row .row.collapse:after {
	content: " ";
	display: table;
}

.row .row.collapse:after {
	clear: both;
}

.column,
.columns {
	position: relative;
	padding-left: .9375em;
	padding-right: .9375em;
	width: 100%;
	float: left;
}

@media only screen {
	.column.small-centered,
	.columns.small-centered {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}

	.column.small-uncentered,
	.columns.small-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left;
	}

	.column.small-uncentered.opposite,
	.columns.small-uncentered.opposite {
		float: right;
	}

	.small-push-1 {
		position: relative;
		left: 8.33333%;
		right: auto;
	}

	.small-pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto;
	}

	.small-push-2 {
		position: relative;
		left: 16.66667%;
		right: auto;
	}

	.small-pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto;
	}

	.small-push-3 {
		position: relative;
		left: 25%;
		right: auto;
	}

	.small-pull-3 {
		position: relative;
		right: 25%;
		left: auto;
	}

	.small-push-4 {
		position: relative;
		left: 33.33333%;
		right: auto;
	}

	.small-pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto;
	}

	.small-push-5 {
		position: relative;
		left: 41.66667%;
		right: auto;
	}

	.small-pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto;
	}

	.small-push-6 {
		position: relative;
		left: 50%;
		right: auto;
	}

	.small-pull-6 {
		position: relative;
		right: 50%;
		left: auto;
	}

	.small-push-7 {
		position: relative;
		left: 58.33333%;
		right: auto;
	}

	.small-pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto;
	}

	.small-push-8 {
		position: relative;
		left: 66.66667%;
		right: auto;
	}

	.small-pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto;
	}

	.small-push-9 {
		position: relative;
		left: 75%;
		right: auto;
	}

	.small-pull-9 {
		position: relative;
		right: 75%;
		left: auto;
	}

	.small-push-10 {
		position: relative;
		left: 83.33333%;
		right: auto;
	}

	.small-pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto;
	}

	.small-push-11 {
		position: relative;
		left: 91.66667%;
		right: auto;
	}

	.small-pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto;
	}

	.column,
	.columns {
		position: relative;
		padding-left: .9375em;
		padding-right: .9375em;
		float: left;
	}

	.small-1 {
		position: relative;
		width: 8.33333%;
	}

	.small-2 {
		position: relative;
		width: 16.66667%;
	}

	.small-3 {
		position: relative;
		width: 25%;
	}

	.small-4 {
		position: relative;
		width: 33.33333%;
	}

	.small-5 {
		position: relative;
		width: 41.66667%;
	}

	.small-6 {
		position: relative;
		width: 50%;
	}

	.small-7 {
		position: relative;
		width: 58.33333%;
	}

	.small-8 {
		position: relative;
		width: 66.66667%;
	}

	.small-9 {
		position: relative;
		width: 75%;
	}

	.small-10 {
		position: relative;
		width: 83.33333%;
	}

	.small-11 {
		position: relative;
		width: 91.66667%;
	}

	.small-12 {
		position: relative;
		width: 100%;
	}

	[class*="column"] + [class*="column"].end {
		float: left;
	}

	.small-offset-0 {
		position: relative;
		margin-left: 0% !important;
	}

	.small-offset-1 {
		position: relative;
		margin-left: 8.33333% !important;
	}

	.small-offset-2 {
		position: relative;
		margin-left: 16.66667% !important;
	}

	.small-offset-3 {
		position: relative;
		margin-left: 25% !important;
	}

	.small-offset-4 {
		position: relative;
		margin-left: 33.33333% !important;
	}

	.small-offset-5 {
		position: relative;
		margin-left: 41.66667% !important;
	}

	.small-offset-6 {
		position: relative;
		margin-left: 50% !important;
	}

	.small-offset-7 {
		position: relative;
		margin-left: 58.33333% !important;
	}

	.small-offset-8 {
		position: relative;
		margin-left: 66.66667% !important;
	}

	.small-offset-9 {
		position: relative;
		margin-left: 75% !important;
	}

	.small-offset-10 {
		position: relative;
		margin-left: 83.33333% !important;
	}

	.column.small-reset-order,
	.columns.small-reset-order {
		margin-left: 0;
		margin-right: 0;
		left: auto;
		right: auto;
		float: left;
	}
}

@media only screen and (min-width: 40.063em) {
	.column.medium-centered,
	.columns.medium-centered {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}

	.column.medium-uncentered,
	.columns.medium-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left;
	}

	.column.medium-uncentered.opposite,
	.columns.medium-uncentered.opposite {
		float: right;
	}

	.medium-push-1 {
		position: relative;
		left: 8.33333%;
		right: auto;
	}

	.medium-pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto;
	}

	.medium-push-2 {
		position: relative;
		left: 16.66667%;
		right: auto;
	}

	.medium-pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto;
	}

	.medium-push-3 {
		position: relative;
		left: 25%;
		right: auto;
	}

	.medium-pull-3 {
		position: relative;
		right: 25%;
		left: auto;
	}

	.medium-push-4 {
		position: relative;
		left: 33.33333%;
		right: auto;
	}

	.medium-pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto;
	}

	.medium-push-5 {
		position: relative;
		left: 41.66667%;
		right: auto;
	}

	.medium-pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto;
	}

	.medium-push-6 {
		position: relative;
		left: 50%;
		right: auto;
	}

	.medium-pull-6 {
		position: relative;
		right: 50%;
		left: auto;
	}

	.medium-push-7 {
		position: relative;
		left: 58.33333%;
		right: auto;
	}

	.medium-pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto;
	}

	.medium-push-8 {
		position: relative;
		left: 66.66667%;
		right: auto;
	}

	.medium-pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto;
	}

	.medium-push-9 {
		position: relative;
		left: 75%;
		right: auto;
	}

	.medium-pull-9 {
		position: relative;
		right: 75%;
		left: auto;
	}

	.medium-push-10 {
		position: relative;
		left: 83.33333%;
		right: auto;
	}

	.medium-pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto;
	}

	.medium-push-11 {
		position: relative;
		left: 91.66667%;
		right: auto;
	}

	.medium-pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto;
	}

	.column,
	.columns {
		position: relative;
		padding-left: .9375em;
		padding-right: .9375em;
		float: left;
	}

	.medium-1 {
		position: relative;
		width: 8.33333%;
	}

	.medium-2 {
		position: relative;
		width: 16.66667%;
	}

	.medium-3 {
		position: relative;
		width: 25%;
	}

	.medium-4 {
		position: relative;
		width: 33.33333%;
	}

	.medium-5 {
		position: relative;
		width: 41.66667%;
	}

	.medium-6 {
		position: relative;
		width: 50%;
	}

	.medium-7 {
		position: relative;
		width: 58.33333%;
	}

	.medium-8 {
		position: relative;
		width: 66.66667%;
	}

	.medium-9 {
		position: relative;
		width: 75%;
	}

	.medium-10 {
		position: relative;
		width: 83.33333%;
	}

	.medium-11 {
		position: relative;
		width: 91.66667%;
	}

	.medium-12 {
		position: relative;
		width: 100%;
	}

	[class*="column"] + [class*="column"].end {
		float: left;
	}

	.medium-offset-0 {
		position: relative;
		margin-left: 0% !important;
	}

	.medium-offset-1 {
		position: relative;
		margin-left: 8.33333% !important;
	}

	.medium-offset-2 {
		position: relative;
		margin-left: 16.66667% !important;
	}

	.medium-offset-3 {
		position: relative;
		margin-left: 25% !important;
	}

	.medium-offset-4 {
		position: relative;
		margin-left: 33.33333% !important;
	}

	.medium-offset-5 {
		position: relative;
		margin-left: 41.66667% !important;
	}

	.medium-offset-6 {
		position: relative;
		margin-left: 50% !important;
	}

	.medium-offset-7 {
		position: relative;
		margin-left: 58.33333% !important;
	}

	.medium-offset-8 {
		position: relative;
		margin-left: 66.66667% !important;
	}

	.medium-offset-9 {
		position: relative;
		margin-left: 75% !important;
	}

	.medium-offset-10 {
		position: relative;
		margin-left: 83.33333% !important;
	}

	.column.medium-reset-order,
	.columns.medium-reset-order {
		margin-left: 0;
		margin-right: 0;
		left: auto;
		right: auto;
		float: left;
	}

	.push-1 {
		position: relative;
		left: 8.33333%;
		right: auto;
	}

	.pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto;
	}

	.push-2 {
		position: relative;
		left: 16.66667%;
		right: auto;
	}

	.pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto;
	}

	.push-3 {
		position: relative;
		left: 25%;
		right: auto;
	}

	.pull-3 {
		position: relative;
		right: 25%;
		left: auto;
	}

	.push-4 {
		position: relative;
		left: 33.33333%;
		right: auto;
	}

	.pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto;
	}

	.push-5 {
		position: relative;
		left: 41.66667%;
		right: auto;
	}

	.pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto;
	}

	.push-6 {
		position: relative;
		left: 50%;
		right: auto;
	}

	.pull-6 {
		position: relative;
		right: 50%;
		left: auto;
	}

	.push-7 {
		position: relative;
		left: 58.33333%;
		right: auto;
	}

	.pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto;
	}

	.push-8 {
		position: relative;
		left: 66.66667%;
		right: auto;
	}

	.pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto;
	}

	.push-9 {
		position: relative;
		left: 75%;
		right: auto;
	}

	.pull-9 {
		position: relative;
		right: 75%;
		left: auto;
	}

	.push-10 {
		position: relative;
		left: 83.33333%;
		right: auto;
	}

	.pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto;
	}

	.push-11 {
		position: relative;
		left: 91.66667%;
		right: auto;
	}

	.pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto;
	}
}

@media only screen and (min-width: 64.063em) {
	.column.large-centered,
	.columns.large-centered {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		float: none;
	}

	.column.large-uncentered,
	.columns.large-uncentered {
		margin-left: 0;
		margin-right: 0;
		float: left;
	}

	.column.large-uncentered.opposite,
	.columns.large-uncentered.opposite {
		float: right;
	}

	.large-push-1 {
		position: relative;
		left: 8.33333%;
		right: auto;
	}

	.large-pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto;
	}

	.large-push-2 {
		position: relative;
		left: 16.66667%;
		right: auto;
	}

	.large-pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto;
	}

	.large-push-3 {
		position: relative;
		left: 25%;
		right: auto;
	}

	.large-pull-3 {
		position: relative;
		right: 25%;
		left: auto;
	}

	.large-push-4 {
		position: relative;
		left: 33.33333%;
		right: auto;
	}

	.large-pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto;
	}

	.large-push-5 {
		position: relative;
		left: 41.66667%;
		right: auto;
	}

	.large-pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto;
	}

	.large-push-6 {
		position: relative;
		left: 50%;
		right: auto;
	}

	.large-pull-6 {
		position: relative;
		right: 50%;
		left: auto;
	}

	.large-push-7 {
		position: relative;
		left: 58.33333%;
		right: auto;
	}

	.large-pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto;
	}

	.large-push-8 {
		position: relative;
		left: 66.66667%;
		right: auto;
	}

	.large-pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto;
	}

	.large-push-9 {
		position: relative;
		left: 75%;
		right: auto;
	}

	.large-pull-9 {
		position: relative;
		right: 75%;
		left: auto;
	}

	.large-push-10 {
		position: relative;
		left: 83.33333%;
		right: auto;
	}

	.large-pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto;
	}

	.large-push-11 {
		position: relative;
		left: 91.66667%;
		right: auto;
	}

	.large-pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto;
	}

	.column,
	.columns {
		position: relative;
		padding-left: .9375em;
		padding-right: .9375em;
		float: left;
	}

	.large-1 {
		position: relative;
		width: 8.33333%;
	}

	.large-2 {
		position: relative;
		width: 16.66667%;
	}

	.large-3 {
		position: relative;
		width: 25%;
	}

	.large-4 {
		position: relative;
		width: 33.33333%;
	}

	.large-5 {
		position: relative;
		width: 41.66667%;
	}

	.large-6 {
		position: relative;
		width: 50%;
	}

	.large-7 {
		position: relative;
		width: 58.33333%;
	}

	.large-8 {
		position: relative;
		width: 66.66667%;
	}

	.large-9 {
		position: relative;
		width: 75%;
	}

	.large-10 {
		position: relative;
		width: 83.33333%;
	}

	.large-11 {
		position: relative;
		width: 91.66667%;
	}

	.large-12 {
		position: relative;
		width: 100%;
	}

	[class*="column"] + [class*="column"].end {
		float: left;
	}

	.large-offset-0 {
		position: relative;
		margin-left: 0% !important;
	}

	.large-offset-1 {
		position: relative;
		margin-left: 8.33333% !important;
	}

	.large-offset-2 {
		position: relative;
		margin-left: 16.66667% !important;
	}

	.large-offset-3 {
		position: relative;
		margin-left: 25% !important;
	}

	.large-offset-4 {
		position: relative;
		margin-left: 33.33333% !important;
	}

	.large-offset-5 {
		position: relative;
		margin-left: 41.66667% !important;
	}

	.large-offset-6 {
		position: relative;
		margin-left: 50% !important;
	}

	.large-offset-7 {
		position: relative;
		margin-left: 58.33333% !important;
	}

	.large-offset-8 {
		position: relative;
		margin-left: 66.66667% !important;
	}

	.large-offset-9 {
		position: relative;
		margin-left: 75% !important;
	}

	.large-offset-10 {
		position: relative;
		margin-left: 83.33333% !important;
	}

	.column.large-reset-order,
	.columns.large-reset-order {
		margin-left: 0;
		margin-right: 0;
		left: auto;
		right: auto;
		float: left;
	}

	.push-1 {
		position: relative;
		left: 8.33333%;
		right: auto;
	}

	.pull-1 {
		position: relative;
		right: 8.33333%;
		left: auto;
	}

	.push-2 {
		position: relative;
		left: 16.66667%;
		right: auto;
	}

	.pull-2 {
		position: relative;
		right: 16.66667%;
		left: auto;
	}

	.push-3 {
		position: relative;
		left: 25%;
		right: auto;
	}

	.pull-3 {
		position: relative;
		right: 25%;
		left: auto;
	}

	.push-4 {
		position: relative;
		left: 33.33333%;
		right: auto;
	}

	.pull-4 {
		position: relative;
		right: 33.33333%;
		left: auto;
	}

	.push-5 {
		position: relative;
		left: 41.66667%;
		right: auto;
	}

	.pull-5 {
		position: relative;
		right: 41.66667%;
		left: auto;
	}

	.push-6 {
		position: relative;
		left: 50%;
		right: auto;
	}

	.pull-6 {
		position: relative;
		right: 50%;
		left: auto;
	}

	.push-7 {
		position: relative;
		left: 58.33333%;
		right: auto;
	}

	.pull-7 {
		position: relative;
		right: 58.33333%;
		left: auto;
	}

	.push-8 {
		position: relative;
		left: 66.66667%;
		right: auto;
	}

	.pull-8 {
		position: relative;
		right: 66.66667%;
		left: auto;
	}

	.push-9 {
		position: relative;
		left: 75%;
		right: auto;
	}

	.pull-9 {
		position: relative;
		right: 75%;
		left: auto;
	}

	.push-10 {
		position: relative;
		left: 83.33333%;
		right: auto;
	}

	.pull-10 {
		position: relative;
		right: 83.33333%;
		left: auto;
	}

	.push-11 {
		position: relative;
		left: 91.66667%;
		right: auto;
	}

	.pull-11 {
		position: relative;
		right: 91.66667%;
		left: auto;
	}
}

/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/

.print-only {
	display: none !important;
}

@media print {
	* {
		background: transparent !important;
		color: black !important;

		/* Black prints faster: h5bp.com/s */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;

		/* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	// Maint 2204 - AMSU-271 - Unsure what this actually does, commented for now

	/*
		$page {
		margin: 0.5cm; }
		*/

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	.hide-on-print {
		display: none !important;
	}

	.print-only {
		display: block !important;
	}

	.hide-for-print {
		display: none !important;
	}

	.show-for-print {
		display: inherit !important;
	}
}

[class*="block-grid-"] {
	display: block;
	padding: 0;
	margin: 0 0 0 -.625rem;
	*zoom: 1;
}

[class*="block-grid-"]:before,
[class*="block-grid-"]:after {
	content: " ";
	display: table;
}

[class*="block-grid-"]:after {
	clear: both;
}

[class*="block-grid-"] > li {
	display: inline;
	height: auto;
	float: left;
	padding: 0 .625rem 1.25rem;
}

@media only screen {
	.small-block-grid-1 > li {
		width: 100%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-1 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-1 > li:nth-of-type(1n+1) {
		clear: both;
	}

	.small-block-grid-2 > li {
		width: 50%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-2 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-2 > li:nth-of-type(2n+1) {
		clear: both;
	}

	.small-block-grid-3 > li {
		width: 33.33333%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-3 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-3 > li:nth-of-type(3n+1) {
		clear: both;
	}

	.small-block-grid-4 > li {
		width: 25%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-4 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-4 > li:nth-of-type(4n+1) {
		clear: both;
	}

	.small-block-grid-5 > li {
		width: 20%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-5 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-5 > li:nth-of-type(5n+1) {
		clear: both;
	}

	.small-block-grid-6 > li {
		width: 16.66667%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-6 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-6 > li:nth-of-type(6n+1) {
		clear: both;
	}

	.small-block-grid-7 > li {
		width: 14.28571%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-7 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-7 > li:nth-of-type(7n+1) {
		clear: both;
	}

	.small-block-grid-8 > li {
		width: 12.5%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-8 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-8 > li:nth-of-type(8n+1) {
		clear: both;
	}

	.small-block-grid-9 > li {
		width: 11.11111%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-9 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-9 > li:nth-of-type(9n+1) {
		clear: both;
	}

	.small-block-grid-10 > li {
		width: 10%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-10 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-10 > li:nth-of-type(10n+1) {
		clear: both;
	}

	.small-block-grid-11 > li {
		width: 9.09091%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-11 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-11 > li:nth-of-type(11n+1) {
		clear: both;
	}

	.small-block-grid-12 > li {
		width: 8.33333%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.small-block-grid-12 > li:nth-of-type(n) {
		clear: none;
	}

	.small-block-grid-12 > li:nth-of-type(12n+1) {
		clear: both;
	}
}

@media only screen and (min-width: 40.063em) {
	.medium-block-grid-1 > li {
		width: 100%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-1 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-1 > li:nth-of-type(1n+1) {
		clear: both;
	}

	.medium-block-grid-2 > li {
		width: 50%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-2 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-2 > li:nth-of-type(2n+1) {
		clear: both;
	}

	.medium-block-grid-3 > li {
		width: 33.33333%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-3 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-3 > li:nth-of-type(3n+1) {
		clear: both;
	}

	.medium-block-grid-4 > li {
		width: 25%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-4 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-4 > li:nth-of-type(4n+1) {
		clear: both;
	}

	.medium-block-grid-5 > li {
		width: 20%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-5 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-5 > li:nth-of-type(5n+1) {
		clear: both;
	}

	.medium-block-grid-6 > li {
		width: 16.66667%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-6 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-6 > li:nth-of-type(6n+1) {
		clear: both;
	}

	.medium-block-grid-7 > li {
		width: 14.28571%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-7 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-7 > li:nth-of-type(7n+1) {
		clear: both;
	}

	.medium-block-grid-8 > li {
		width: 12.5%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-8 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-8 > li:nth-of-type(8n+1) {
		clear: both;
	}

	.medium-block-grid-9 > li {
		width: 11.11111%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-9 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-9 > li:nth-of-type(9n+1) {
		clear: both;
	}

	.medium-block-grid-10 > li {
		width: 10%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-10 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-10 > li:nth-of-type(10n+1) {
		clear: both;
	}

	.medium-block-grid-11 > li {
		width: 9.09091%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-11 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-11 > li:nth-of-type(11n+1) {
		clear: both;
	}

	.medium-block-grid-12 > li {
		width: 8.33333%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.medium-block-grid-12 > li:nth-of-type(n) {
		clear: none;
	}

	.medium-block-grid-12 > li:nth-of-type(12n+1) {
		clear: both;
	}
}

@media only screen and (min-width: 64.063em) {
	.large-block-grid-1 > li {
		width: 100%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-1 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-1 > li:nth-of-type(1n+1) {
		clear: both;
	}

	.large-block-grid-2 > li {
		width: 50%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-2 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-2 > li:nth-of-type(2n+1) {
		clear: both;
	}

	.large-block-grid-3 > li {
		width: 33.33333%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-3 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-3 > li:nth-of-type(3n+1) {
		clear: both;
	}

	.large-block-grid-4 > li {
		width: 25%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-4 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-4 > li:nth-of-type(4n+1) {
		clear: both;
	}

	.large-block-grid-5 > li {
		width: 20%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-5 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-5 > li:nth-of-type(5n+1) {
		clear: both;
	}

	.large-block-grid-6 > li {
		width: 16.66667%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-6 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-6 > li:nth-of-type(6n+1) {
		clear: both;
	}

	.large-block-grid-7 > li {
		width: 14.28571%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-7 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-7 > li:nth-of-type(7n+1) {
		clear: both;
	}

	.large-block-grid-8 > li {
		width: 12.5%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-8 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-8 > li:nth-of-type(8n+1) {
		clear: both;
	}

	.large-block-grid-9 > li {
		width: 11.11111%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-9 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-9 > li:nth-of-type(9n+1) {
		clear: both;
	}

	.large-block-grid-10 > li {
		width: 10%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-10 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-10 > li:nth-of-type(10n+1) {
		clear: both;
	}

	.large-block-grid-11 > li {
		width: 9.09091%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-11 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-11 > li:nth-of-type(11n+1) {
		clear: both;
	}

	.large-block-grid-12 > li {
		width: 8.3333%;
		padding: 0 .625rem 1.25rem;
		list-style: none;
	}

	.large-block-grid-12 > li:nth-of-type(n) {
		clear: none;
	}

	.large-block-grid-12 > li:nth-of-type(12n+1) {
		clear: both;
	}
}

meta.foundation-version {
	font-family: "/5.4.7/";
}

meta.foundation-mq-small {
	font-family: "/only screen/";
	width: 0;
}

meta.foundation-mq-medium {
	font-family: "/only screen and (min-width:40.063em)/";
	width: 40.063em;
}

meta.foundation-mq-large {
	font-family: "/only screen and (min-width:64.063em)/";
	width: 64.063em;
}

meta.foundation-mq-xlarge {
	font-family: "/only screen and (min-width:90.063em)/";
	width: 90.063em;
}

meta.foundation-mq-xxlarge {
	font-family: "/only screen and (min-width:120.063em)/";
	width: 120.063em;
}

meta.foundation-data-attribute-namespace {
	font-family: false;
}

html,
body {
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	font-size: 100%;
}

body {
	background: white;
	color: #222;
	padding: 0;
	margin: 0;
	font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
	line-height: 1.5;
	position: relative;
	cursor: auto;
}

a:hover {
	cursor: pointer;
}

img {
	max-width: 100%;
	height: auto;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
	max-width: none !important;
}

.left {
	float: left !important;
}

.right {
	float: right !important;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.hide {
	display: none !important;
	visibility: hidden;
}

.invisible {
	visibility: hidden;
}

.antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	display: inline-block;
	vertical-align: middle;
}

textarea {
	height: auto;
	min-height: 50px;
}

select {
	width: 100%;
}

/* small displays */
@media only screen {
	.show-for-small-only,
	.show-for-small-up,
	.show-for-small,
	.show-for-small-down,
	.hide-for-medium-only,
	.hide-for-medium-up,
	.hide-for-medium,
	.show-for-medium-down,
	.hide-for-large-only,
	.hide-for-large-up,
	.hide-for-large,
	.show-for-large-down,
	.hide-for-xlarge-only,
	.hide-for-xlarge-up,
	.hide-for-xxlarge-only,
	.hide-for-xxlarge-up {
		display: inherit !important;
	}

	.hide-for-small-only,
	.hide-for-small-up,
	.hide-for-small,
	.hide-for-small-down,
	.show-for-medium-only,
	.show-for-medium-up,
	.show-for-medium,
	.hide-for-medium-down,
	.show-for-large-only,
	.show-for-large-up,
	.show-for-large,
	.hide-for-large-down,
	.show-for-xlarge-only,
	.show-for-xlarge-up,
	.show-for-xxlarge-only,
	.show-for-xxlarge-up {
		display: none !important;
	}

	.visible-for-small-only,
	.visible-for-small-up,
	.visible-for-small,
	.visible-for-small-down,
	.hidden-for-medium-only,
	.hidden-for-medium-up,
	.hidden-for-medium,
	.visible-for-medium-down,
	.hidden-for-large-only,
	.hidden-for-large-up,
	.hidden-for-large,
	.visible-for-large-down,
	.hidden-for-xlarge-only,
	.hidden-for-xlarge-up,
	.hidden-for-xxlarge-only,
	.hidden-for-xxlarge-up {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	.hidden-for-small-only,
	.hidden-for-small-up,
	.hidden-for-small,
	.hidden-for-small-down,
	.visible-for-medium-only,
	.visible-for-medium-up,
	.visible-for-medium,
	.hidden-for-medium-down,
	.visible-for-large-only,
	.visible-for-large-up,
	.visible-for-large,
	.hidden-for-large-down,
	.visible-for-xlarge-only,
	.visible-for-xlarge-up,
	.visible-for-xxlarge-only,
	.visible-for-xxlarge-up {
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	table.show-for-small-only,
	table.show-for-small-up,
	table.show-for-small,
	table.show-for-small-down,
	table.hide-for-medium-only,
	table.hide-for-medium-up,
	table.hide-for-medium,
	table.show-for-medium-down,
	table.hide-for-large-only,
	table.hide-for-large-up,
	table.hide-for-large,
	table.show-for-large-down,
	table.hide-for-xlarge-only,
	table.hide-for-xlarge-up,
	table.hide-for-xxlarge-only,
	table.hide-for-xxlarge-up {
		display: table !important;
	}

	thead.show-for-small-only,
	thead.show-for-small-up,
	thead.show-for-small,
	thead.show-for-small-down,
	thead.hide-for-medium-only,
	thead.hide-for-medium-up,
	thead.hide-for-medium,
	thead.show-for-medium-down,
	thead.hide-for-large-only,
	thead.hide-for-large-up,
	thead.hide-for-large,
	thead.show-for-large-down,
	thead.hide-for-xlarge-only,
	thead.hide-for-xlarge-up,
	thead.hide-for-xxlarge-only,
	thead.hide-for-xxlarge-up {
		display: table-header-group !important;
	}

	tbody.show-for-small-only,
	tbody.show-for-small-up,
	tbody.show-for-small,
	tbody.show-for-small-down,
	tbody.hide-for-medium-only,
	tbody.hide-for-medium-up,
	tbody.hide-for-medium,
	tbody.show-for-medium-down,
	tbody.hide-for-large-only,
	tbody.hide-for-large-up,
	tbody.hide-for-large,
	tbody.show-for-large-down,
	tbody.hide-for-xlarge-only,
	tbody.hide-for-xlarge-up,
	tbody.hide-for-xxlarge-only,
	tbody.hide-for-xxlarge-up {
		display: table-row-group !important;
	}

	tr.show-for-small-only,
	tr.show-for-small-up,
	tr.show-for-small,
	tr.show-for-small-down,
	tr.hide-for-medium-only,
	tr.hide-for-medium-up,
	tr.hide-for-medium,
	tr.show-for-medium-down,
	tr.hide-for-large-only,
	tr.hide-for-large-up,
	tr.hide-for-large,
	tr.show-for-large-down,
	tr.hide-for-xlarge-only,
	tr.hide-for-xlarge-up,
	tr.hide-for-xxlarge-only,
	tr.hide-for-xxlarge-up {
		display: table-row !important;
	}

	th.show-for-small-only,
	td.show-for-small-only,
	th.show-for-small-up,
	td.show-for-small-up,
	th.show-for-small,
	td.show-for-small,
	th.show-for-small-down,
	td.show-for-small-down,
	th.hide-for-medium-only,
	td.hide-for-medium-only,
	th.hide-for-medium-up,
	td.hide-for-medium-up,
	th.hide-for-medium,
	td.hide-for-medium,
	th.show-for-medium-down,
	td.show-for-medium-down,
	th.hide-for-large-only,
	td.hide-for-large-only,
	th.hide-for-large-up,
	td.hide-for-large-up,
	th.hide-for-large,
	td.hide-for-large,
	th.show-for-large-down,
	td.show-for-large-down,
	th.hide-for-xlarge-only,
	td.hide-for-xlarge-only,
	th.hide-for-xlarge-up,
	td.hide-for-xlarge-up,
	th.hide-for-xxlarge-only,
	td.hide-for-xxlarge-only,
	th.hide-for-xxlarge-up,
	td.hide-for-xxlarge-up {
		display: table-cell !important;
	}
}

/* medium displays */
@media only screen and (min-width: 40.063em) {
	.hide-for-small-only,
	.show-for-small-up,
	.hide-for-small,
	.hide-for-small-down,
	.show-for-medium-only,
	.show-for-medium-up,
	.show-for-medium,
	.show-for-medium-down,
	.hide-for-large-only,
	.hide-for-large-up,
	.hide-for-large,
	.show-for-large-down,
	.hide-for-xlarge-only,
	.hide-for-xlarge-up,
	.hide-for-xxlarge-only,
	.hide-for-xxlarge-up {
		display: inherit !important;
	}

	.show-for-small-only,
	.hide-for-small-up,
	.show-for-small,
	.show-for-small-down,
	.hide-for-medium-only,
	.hide-for-medium-up,
	.hide-for-medium,
	.hide-for-medium-down,
	.show-for-large-only,
	.show-for-large-up,
	.show-for-large,
	.hide-for-large-down,
	.show-for-xlarge-only,
	.show-for-xlarge-up,
	.show-for-xxlarge-only,
	.show-for-xxlarge-up {
		display: none !important;
	}

	.hidden-for-small-only,
	.visible-for-small-up,
	.hidden-for-small,
	.hidden-for-small-down,
	.visible-for-medium-only,
	.visible-for-medium-up,
	.visible-for-medium,
	.visible-for-medium-down,
	.hidden-for-large-only,
	.hidden-for-large-up,
	.hidden-for-large,
	.visible-for-large-down,
	.hidden-for-xlarge-only,
	.hidden-for-xlarge-up,
	.hidden-for-xxlarge-only,
	.hidden-for-xxlarge-up {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	.visible-for-small-only,
	.hidden-for-small-up,
	.visible-for-small,
	.visible-for-small-down,
	.hidden-for-medium-only,
	.hidden-for-medium-up,
	.hidden-for-medium,
	.hidden-for-medium-down,
	.visible-for-large-only,
	.visible-for-large-up,
	.visible-for-large,
	.hidden-for-large-down,
	.visible-for-xlarge-only,
	.visible-for-xlarge-up,
	.visible-for-xxlarge-only,
	.visible-for-xxlarge-up {
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	table.hide-for-small-only,
	table.show-for-small-up,
	table.hide-for-small,
	table.hide-for-small-down,
	table.show-for-medium-only,
	table.show-for-medium-up,
	table.show-for-medium,
	table.show-for-medium-down,
	table.hide-for-large-only,
	table.hide-for-large-up,
	table.hide-for-large,
	table.show-for-large-down,
	table.hide-for-xlarge-only,
	table.hide-for-xlarge-up,
	table.hide-for-xxlarge-only,
	table.hide-for-xxlarge-up {
		display: table !important;
	}

	thead.hide-for-small-only,
	thead.show-for-small-up,
	thead.hide-for-small,
	thead.hide-for-small-down,
	thead.show-for-medium-only,
	thead.show-for-medium-up,
	thead.show-for-medium,
	thead.show-for-medium-down,
	thead.hide-for-large-only,
	thead.hide-for-large-up,
	thead.hide-for-large,
	thead.show-for-large-down,
	thead.hide-for-xlarge-only,
	thead.hide-for-xlarge-up,
	thead.hide-for-xxlarge-only,
	thead.hide-for-xxlarge-up {
		display: table-header-group !important;
	}

	tbody.hide-for-small-only,
	tbody.show-for-small-up,
	tbody.hide-for-small,
	tbody.hide-for-small-down,
	tbody.show-for-medium-only,
	tbody.show-for-medium-up,
	tbody.show-for-medium,
	tbody.show-for-medium-down,
	tbody.hide-for-large-only,
	tbody.hide-for-large-up,
	tbody.hide-for-large,
	tbody.show-for-large-down,
	tbody.hide-for-xlarge-only,
	tbody.hide-for-xlarge-up,
	tbody.hide-for-xxlarge-only,
	tbody.hide-for-xxlarge-up {
		display: table-row-group !important;
	}

	tr.hide-for-small-only,
	tr.show-for-small-up,
	tr.hide-for-small,
	tr.hide-for-small-down,
	tr.show-for-medium-only,
	tr.show-for-medium-up,
	tr.show-for-medium,
	tr.show-for-medium-down,
	tr.hide-for-large-only,
	tr.hide-for-large-up,
	tr.hide-for-large,
	tr.show-for-large-down,
	tr.hide-for-xlarge-only,
	tr.hide-for-xlarge-up,
	tr.hide-for-xxlarge-only,
	tr.hide-for-xxlarge-up {
		display: table-row !important;
	}

	th.hide-for-small-only,
	td.hide-for-small-only,
	th.show-for-small-up,
	td.show-for-small-up,
	th.hide-for-small,
	td.hide-for-small,
	th.hide-for-small-down,
	td.hide-for-small-down,
	th.show-for-medium-only,
	td.show-for-medium-only,
	th.show-for-medium-up,
	td.show-for-medium-up,
	th.show-for-medium,
	td.show-for-medium,
	th.show-for-medium-down,
	td.show-for-medium-down,
	th.hide-for-large-only,
	td.hide-for-large-only,
	th.hide-for-large-up,
	td.hide-for-large-up,
	th.hide-for-large,
	td.hide-for-large,
	th.show-for-large-down,
	td.show-for-large-down,
	th.hide-for-xlarge-only,
	td.hide-for-xlarge-only,
	th.hide-for-xlarge-up,
	td.hide-for-xlarge-up,
	th.hide-for-xxlarge-only,
	td.hide-for-xxlarge-only,
	th.hide-for-xxlarge-up,
	td.hide-for-xxlarge-up {
		display: table-cell !important;
	}
}

/* large displays */
@media only screen and (min-width: 64.063em) {
	.hide-for-small-only,
	.show-for-small-up,
	.hide-for-small,
	.hide-for-small-down,
	.hide-for-medium-only,
	.show-for-medium-up,
	.hide-for-medium,
	.hide-for-medium-down,
	.show-for-large-only,
	.show-for-large-up,
	.show-for-large,
	.show-for-large-down,
	.hide-for-xlarge-only,
	.hide-for-xlarge-up,
	.hide-for-xxlarge-only,
	.hide-for-xxlarge-up {
		display: inherit !important;
	}

	.show-for-small-only,
	.hide-for-small-up,
	.show-for-small,
	.show-for-small-down,
	.show-for-medium-only,
	.hide-for-medium-up,
	.show-for-medium,
	.show-for-medium-down,
	.hide-for-large-only,
	.hide-for-large-up,
	.hide-for-large,
	.hide-for-large-down,
	.show-for-xlarge-only,
	.show-for-xlarge-up,
	.show-for-xxlarge-only,
	.show-for-xxlarge-up {
		display: none !important;
	}

	.hidden-for-small-only,
	.visible-for-small-up,
	.hidden-for-small,
	.hidden-for-small-down,
	.hidden-for-medium-only,
	.visible-for-medium-up,
	.hidden-for-medium,
	.hidden-for-medium-down,
	.visible-for-large-only,
	.visible-for-large-up,
	.visible-for-large,
	.visible-for-large-down,
	.hidden-for-xlarge-only,
	.hidden-for-xlarge-up,
	.hidden-for-xxlarge-only,
	.hidden-for-xxlarge-up {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	.visible-for-small-only,
	.hidden-for-small-up,
	.visible-for-small,
	.visible-for-small-down,
	.visible-for-medium-only,
	.hidden-for-medium-up,
	.visible-for-medium,
	.visible-for-medium-down,
	.hidden-for-large-only,
	.hidden-for-large-up,
	.hidden-for-large,
	.hidden-for-large-down,
	.visible-for-xlarge-only,
	.visible-for-xlarge-up,
	.visible-for-xxlarge-only,
	.visible-for-xxlarge-up {
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	table.hide-for-small-only,
	table.show-for-small-up,
	table.hide-for-small,
	table.hide-for-small-down,
	table.hide-for-medium-only,
	table.show-for-medium-up,
	table.hide-for-medium,
	table.hide-for-medium-down,
	table.show-for-large-only,
	table.show-for-large-up,
	table.show-for-large,
	table.show-for-large-down,
	table.hide-for-xlarge-only,
	table.hide-for-xlarge-up,
	table.hide-for-xxlarge-only,
	table.hide-for-xxlarge-up {
		display: table !important;
	}

	thead.hide-for-small-only,
	thead.show-for-small-up,
	thead.hide-for-small,
	thead.hide-for-small-down,
	thead.hide-for-medium-only,
	thead.show-for-medium-up,
	thead.hide-for-medium,
	thead.hide-for-medium-down,
	thead.show-for-large-only,
	thead.show-for-large-up,
	thead.show-for-large,
	thead.show-for-large-down,
	thead.hide-for-xlarge-only,
	thead.hide-for-xlarge-up,
	thead.hide-for-xxlarge-only,
	thead.hide-for-xxlarge-up {
		display: table-header-group !important;
	}

	tbody.hide-for-small-only,
	tbody.show-for-small-up,
	tbody.hide-for-small,
	tbody.hide-for-small-down,
	tbody.hide-for-medium-only,
	tbody.show-for-medium-up,
	tbody.hide-for-medium,
	tbody.hide-for-medium-down,
	tbody.show-for-large-only,
	tbody.show-for-large-up,
	tbody.show-for-large,
	tbody.show-for-large-down,
	tbody.hide-for-xlarge-only,
	tbody.hide-for-xlarge-up,
	tbody.hide-for-xxlarge-only,
	tbody.hide-for-xxlarge-up {
		display: table-row-group !important;
	}

	tr.hide-for-small-only,
	tr.show-for-small-up,
	tr.hide-for-small,
	tr.hide-for-small-down,
	tr.hide-for-medium-only,
	tr.show-for-medium-up,
	tr.hide-for-medium,
	tr.hide-for-medium-down,
	tr.show-for-large-only,
	tr.show-for-large-up,
	tr.show-for-large,
	tr.show-for-large-down,
	tr.hide-for-xlarge-only,
	tr.hide-for-xlarge-up,
	tr.hide-for-xxlarge-only,
	tr.hide-for-xxlarge-up {
		display: table-row !important;
	}

	th.hide-for-small-only,
	td.hide-for-small-only,
	th.show-for-small-up,
	td.show-for-small-up,
	th.hide-for-small,
	td.hide-for-small,
	th.hide-for-small-down,
	td.hide-for-small-down,
	th.hide-for-medium-only,
	td.hide-for-medium-only,
	th.show-for-medium-up,
	td.show-for-medium-up,
	th.hide-for-medium,
	td.hide-for-medium,
	th.hide-for-medium-down,
	td.hide-for-medium-down,
	th.show-for-large-only,
	td.show-for-large-only,
	th.show-for-large-up,
	td.show-for-large-up,
	th.show-for-large,
	td.show-for-large,
	th.show-for-large-down,
	td.show-for-large-down,
	th.hide-for-xlarge-only,
	td.hide-for-xlarge-only,
	th.hide-for-xlarge-up,
	td.hide-for-xlarge-up,
	th.hide-for-xxlarge-only,
	td.hide-for-xxlarge-only,
	th.hide-for-xxlarge-up,
	td.hide-for-xxlarge-up {
		display: table-cell !important;
	}
}

/* xlarge displays */
@media only screen and (min-width: 90.063em) {
	.hide-for-small-only,
	.show-for-small-up,
	.hide-for-small,
	.hide-for-small-down,
	.hide-for-medium-only,
	.show-for-medium-up,
	.hide-for-medium,
	.hide-for-medium-down,
	.hide-for-large-only,
	.show-for-large-up,
	.hide-for-large,
	.hide-for-large-down,
	.show-for-xlarge-only,
	.show-for-xlarge-up,
	.hide-for-xxlarge-only,
	.hide-for-xxlarge-up {
		display: inherit !important;
	}

	.show-for-small-only,
	.hide-for-small-up,
	.show-for-small,
	.show-for-small-down,
	.show-for-medium-only,
	.hide-for-medium-up,
	.show-for-medium,
	.show-for-medium-down,
	.show-for-large-only,
	.hide-for-large-up,
	.show-for-large,
	.show-for-large-down,
	.hide-for-xlarge-only,
	.hide-for-xlarge-up,
	.show-for-xxlarge-only,
	.show-for-xxlarge-up {
		display: none !important;
	}

	.hidden-for-small-only,
	.visible-for-small-up,
	.hidden-for-small,
	.hidden-for-small-down,
	.hidden-for-medium-only,
	.visible-for-medium-up,
	.hidden-for-medium,
	.hidden-for-medium-down,
	.hidden-for-large-only,
	.visible-for-large-up,
	.hidden-for-large,
	.hidden-for-large-down,
	.visible-for-xlarge-only,
	.visible-for-xlarge-up,
	.hidden-for-xxlarge-only,
	.hidden-for-xxlarge-up {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	.visible-for-small-only,
	.hidden-for-small-up,
	.visible-for-small,
	.visible-for-small-down,
	.visible-for-medium-only,
	.hidden-for-medium-up,
	.visible-for-medium,
	.visible-for-medium-down,
	.visible-for-large-only,
	.hidden-for-large-up,
	.visible-for-large,
	.visible-for-large-down,
	.hidden-for-xlarge-only,
	.hidden-for-xlarge-up,
	.visible-for-xxlarge-only,
	.visible-for-xxlarge-up {
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	table.hide-for-small-only,
	table.show-for-small-up,
	table.hide-for-small,
	table.hide-for-small-down,
	table.hide-for-medium-only,
	table.show-for-medium-up,
	table.hide-for-medium,
	table.hide-for-medium-down,
	table.hide-for-large-only,
	table.show-for-large-up,
	table.hide-for-large,
	table.hide-for-large-down,
	table.show-for-xlarge-only,
	table.show-for-xlarge-up,
	table.hide-for-xxlarge-only,
	table.hide-for-xxlarge-up {
		display: table !important;
	}

	thead.hide-for-small-only,
	thead.show-for-small-up,
	thead.hide-for-small,
	thead.hide-for-small-down,
	thead.hide-for-medium-only,
	thead.show-for-medium-up,
	thead.hide-for-medium,
	thead.hide-for-medium-down,
	thead.hide-for-large-only,
	thead.show-for-large-up,
	thead.hide-for-large,
	thead.hide-for-large-down,
	thead.show-for-xlarge-only,
	thead.show-for-xlarge-up,
	thead.hide-for-xxlarge-only,
	thead.hide-for-xxlarge-up {
		display: table-header-group !important;
	}

	tbody.hide-for-small-only,
	tbody.show-for-small-up,
	tbody.hide-for-small,
	tbody.hide-for-small-down,
	tbody.hide-for-medium-only,
	tbody.show-for-medium-up,
	tbody.hide-for-medium,
	tbody.hide-for-medium-down,
	tbody.hide-for-large-only,
	tbody.show-for-large-up,
	tbody.hide-for-large,
	tbody.hide-for-large-down,
	tbody.show-for-xlarge-only,
	tbody.show-for-xlarge-up,
	tbody.hide-for-xxlarge-only,
	tbody.hide-for-xxlarge-up {
		display: table-row-group !important;
	}

	tr.hide-for-small-only,
	tr.show-for-small-up,
	tr.hide-for-small,
	tr.hide-for-small-down,
	tr.hide-for-medium-only,
	tr.show-for-medium-up,
	tr.hide-for-medium,
	tr.hide-for-medium-down,
	tr.hide-for-large-only,
	tr.show-for-large-up,
	tr.hide-for-large,
	tr.hide-for-large-down,
	tr.show-for-xlarge-only,
	tr.show-for-xlarge-up,
	tr.hide-for-xxlarge-only,
	tr.hide-for-xxlarge-up {
		display: table-row !important;
	}

	th.hide-for-small-only,
	td.hide-for-small-only,
	th.show-for-small-up,
	td.show-for-small-up,
	th.hide-for-small,
	td.hide-for-small,
	th.hide-for-small-down,
	td.hide-for-small-down,
	th.hide-for-medium-only,
	td.hide-for-medium-only,
	th.show-for-medium-up,
	td.show-for-medium-up,
	th.hide-for-medium,
	td.hide-for-medium,
	th.hide-for-medium-down,
	td.hide-for-medium-down,
	th.hide-for-large-only,
	td.hide-for-large-only,
	th.show-for-large-up,
	td.show-for-large-up,
	th.hide-for-large,
	td.hide-for-large,
	th.hide-for-large-down,
	td.hide-for-large-down,
	th.show-for-xlarge-only,
	td.show-for-xlarge-only,
	th.show-for-xlarge-up,
	td.show-for-xlarge-up,
	th.hide-for-xxlarge-only,
	td.hide-for-xxlarge-only,
	th.hide-for-xxlarge-up,
	td.hide-for-xxlarge-up {
		display: table-cell !important;
	}
}

/* xxlarge displays */
@media only screen and (min-width: 120.063em) {
	.hide-for-small-only,
	.show-for-small-up,
	.hide-for-small,
	.hide-for-small-down,
	.hide-for-medium-only,
	.show-for-medium-up,
	.hide-for-medium,
	.hide-for-medium-down,
	.hide-for-large-only,
	.show-for-large-up,
	.hide-for-large,
	.hide-for-large-down,
	.hide-for-xlarge-only,
	.show-for-xlarge-up,
	.show-for-xxlarge-only,
	.show-for-xxlarge-up {
		display: inherit !important;
	}

	.show-for-small-only,
	.hide-for-small-up,
	.show-for-small,
	.show-for-small-down,
	.show-for-medium-only,
	.hide-for-medium-up,
	.show-for-medium,
	.show-for-medium-down,
	.show-for-large-only,
	.hide-for-large-up,
	.show-for-large,
	.show-for-large-down,
	.show-for-xlarge-only,
	.hide-for-xlarge-up,
	.hide-for-xxlarge-only,
	.hide-for-xxlarge-up {
		display: none !important;
	}

	.hidden-for-small-only,
	.visible-for-small-up,
	.hidden-for-small,
	.hidden-for-small-down,
	.hidden-for-medium-only,
	.visible-for-medium-up,
	.hidden-for-medium,
	.hidden-for-medium-down,
	.hidden-for-large-only,
	.visible-for-large-up,
	.hidden-for-large,
	.hidden-for-large-down,
	.hidden-for-xlarge-only,
	.visible-for-xlarge-up,
	.visible-for-xxlarge-only,
	.visible-for-xxlarge-up {
		position: static !important;
		height: auto;
		width: auto;
		overflow: visible;
		clip: auto;
	}

	.visible-for-small-only,
	.hidden-for-small-up,
	.visible-for-small,
	.visible-for-small-down,
	.visible-for-medium-only,
	.hidden-for-medium-up,
	.visible-for-medium,
	.visible-for-medium-down,
	.visible-for-large-only,
	.hidden-for-large-up,
	.visible-for-large,
	.visible-for-large-down,
	.visible-for-xlarge-only,
	.hidden-for-xlarge-up,
	.hidden-for-xxlarge-only,
	.hidden-for-xxlarge-up {
		position: absolute !important;
		height: 1px;
		width: 1px;
		overflow: hidden;
		clip: rect(1px, 1px, 1px, 1px);
	}

	table.hide-for-small-only,
	table.show-for-small-up,
	table.hide-for-small,
	table.hide-for-small-down,
	table.hide-for-medium-only,
	table.show-for-medium-up,
	table.hide-for-medium,
	table.hide-for-medium-down,
	table.hide-for-large-only,
	table.show-for-large-up,
	table.hide-for-large,
	table.hide-for-large-down,
	table.hide-for-xlarge-only,
	table.show-for-xlarge-up,
	table.show-for-xxlarge-only,
	table.show-for-xxlarge-up {
		display: table !important;
	}

	thead.hide-for-small-only,
	thead.show-for-small-up,
	thead.hide-for-small,
	thead.hide-for-small-down,
	thead.hide-for-medium-only,
	thead.show-for-medium-up,
	thead.hide-for-medium,
	thead.hide-for-medium-down,
	thead.hide-for-large-only,
	thead.show-for-large-up,
	thead.hide-for-large,
	thead.hide-for-large-down,
	thead.hide-for-xlarge-only,
	thead.show-for-xlarge-up,
	thead.show-for-xxlarge-only,
	thead.show-for-xxlarge-up {
		display: table-header-group !important;
	}

	tbody.hide-for-small-only,
	tbody.show-for-small-up,
	tbody.hide-for-small,
	tbody.hide-for-small-down,
	tbody.hide-for-medium-only,
	tbody.show-for-medium-up,
	tbody.hide-for-medium,
	tbody.hide-for-medium-down,
	tbody.hide-for-large-only,
	tbody.show-for-large-up,
	tbody.hide-for-large,
	tbody.hide-for-large-down,
	tbody.hide-for-xlarge-only,
	tbody.show-for-xlarge-up,
	tbody.show-for-xxlarge-only,
	tbody.show-for-xxlarge-up {
		display: table-row-group !important;
	}

	tr.hide-for-small-only,
	tr.show-for-small-up,
	tr.hide-for-small,
	tr.hide-for-small-down,
	tr.hide-for-medium-only,
	tr.show-for-medium-up,
	tr.hide-for-medium,
	tr.hide-for-medium-down,
	tr.hide-for-large-only,
	tr.show-for-large-up,
	tr.hide-for-large,
	tr.hide-for-large-down,
	tr.hide-for-xlarge-only,
	tr.show-for-xlarge-up,
	tr.show-for-xxlarge-only,
	tr.show-for-xxlarge-up {
		display: table-row !important;
	}

	th.hide-for-small-only,
	td.hide-for-small-only,
	th.show-for-small-up,
	td.show-for-small-up,
	th.hide-for-small,
	td.hide-for-small,
	th.hide-for-small-down,
	td.hide-for-small-down,
	th.hide-for-medium-only,
	td.hide-for-medium-only,
	th.show-for-medium-up,
	td.show-for-medium-up,
	th.hide-for-medium,
	td.hide-for-medium,
	th.hide-for-medium-down,
	td.hide-for-medium-down,
	th.hide-for-large-only,
	td.hide-for-large-only,
	th.show-for-large-up,
	td.show-for-large-up,
	th.hide-for-large,
	td.hide-for-large,
	th.hide-for-large-down,
	td.hide-for-large-down,
	th.hide-for-xlarge-only,
	td.hide-for-xlarge-only,
	th.show-for-xlarge-up,
	td.show-for-xlarge-up,
	th.show-for-xxlarge-only,
	td.show-for-xxlarge-only,
	th.show-for-xxlarge-up,
	td.show-for-xxlarge-up {
		display: table-cell !important;
	}
}

/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
	display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
	display: none !important;
}

/* Specific visibility for tables */
table.hide-for-landscape,
table.show-for-portrait {
	display: table !important;
}

thead.hide-for-landscape,
thead.show-for-portrait {
	display: table-header-group !important;
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
	display: table-row-group !important;
}

tr.hide-for-landscape,
tr.show-for-portrait {
	display: table-row !important;
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
	display: table-cell !important;
}

@media only screen and (orientation: landscape) {
	.show-for-landscape,
	.hide-for-portrait {
		display: inherit !important;
	}

	.hide-for-landscape,
	.show-for-portrait {
		display: none !important;
	}

	/* Specific visibility for tables */
	table.show-for-landscape,
	table.hide-for-portrait {
		display: table !important;
	}

	thead.show-for-landscape,
	thead.hide-for-portrait {
		display: table-header-group !important;
	}

	tbody.show-for-landscape,
	tbody.hide-for-portrait {
		display: table-row-group !important;
	}

	tr.show-for-landscape,
	tr.hide-for-portrait {
		display: table-row !important;
	}

	td.show-for-landscape,
	td.hide-for-portrait,
	th.show-for-landscape,
	th.hide-for-portrait {
		display: table-cell !important;
	}
}

@media only screen and (orientation: portrait) {
	.show-for-portrait,
	.hide-for-landscape {
		display: inherit !important;
	}

	.hide-for-portrait,
	.show-for-landscape {
		display: none !important;
	}

	/* Specific visibility for tables */
	table.show-for-portrait,
	table.hide-for-landscape {
		display: table !important;
	}

	thead.show-for-portrait,
	thead.hide-for-landscape {
		display: table-header-group !important;
	}

	tbody.show-for-portrait,
	tbody.hide-for-landscape {
		display: table-row-group !important;
	}

	tr.show-for-portrait,
	tr.hide-for-landscape {
		display: table-row !important;
	}

	td.show-for-portrait,
	td.hide-for-landscape,
	th.show-for-portrait,
	th.hide-for-landscape {
		display: table-cell !important;
	}
}

/* Touch-enabled device targeting */
.show-for-touch {
	display: none !important;
}

.hide-for-touch {
	display: inherit !important;
}

.touch .show-for-touch {
	display: inherit !important;
}

.touch .hide-for-touch {
	display: none !important;
}

/* Specific visibility for tables */
table.hide-for-touch {
	display: table !important;
}

.touch table.show-for-touch {
	display: table !important;
}

thead.hide-for-touch {
	display: table-header-group !important;
}

.touch thead.show-for-touch {
	display: table-header-group !important;
}

tbody.hide-for-touch {
	display: table-row-group !important;
}

.touch tbody.show-for-touch {
	display: table-row-group !important;
}

tr.hide-for-touch {
	display: table-row !important;
}

.touch tr.show-for-touch {
	display: table-row !important;
}

td.hide-for-touch {
	display: table-cell !important;
}

.touch td.show-for-touch {
	display: table-cell !important;
}

th.hide-for-touch {
	display: table-cell !important;
}

.touch th.show-for-touch {
	display: table-cell !important;
}

/* Print visibility */
@media print {
	.show-for-print {
		display: block;
	}

	.hide-for-print {
		display: none;
	}

	table.show-for-print {
		display: table !important;
	}

	thead.show-for-print {
		display: table-header-group !important;
	}

	tbody.show-for-print {
		display: table-row-group !important;
	}

	tr.show-for-print {
		display: table-row !important;
	}

	td.show-for-print {
		display: table-cell !important;
	}

	th.show-for-print {
		display: table-cell !important;
	}
}
